import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserByEmail } from "../api/getUserByEmail";
import { logoutUser } from "../api/logoutUser";

// Sweet Alert v2
import Swal from "sweetalert2";

export default function ScrollToTop() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const email = localStorage.getItem("email");
  const accessToken = localStorage.getItem("access_token");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    if (
      email &&
      accessToken &&
      pathname &&
      pathname.includes("signIn") === false
    ) {
      getUserByEmail(email)
        .then((res) => {
          if (
            res.data.loginExpiration === undefined ||
            new Date().getTime() >= new Date(res.data.loginExpiration).getTime()
          ) {
            if (localStorage.getItem("access_token")) {
              logoutUser(email)
                .then((res) => {
                  localStorage.removeItem("isVerified");
                  localStorage.removeItem("access_token");
                  localStorage.removeItem("email");
                  navigate("/signIn");
                })
                .catch((err) => {
                  const { message } = err.response.data || {};
                  if (
                    message &&
                    (message === "Unauthorized" ||
                      message === "Unauthenticated" ||
                      message === "TokenExpiredError")
                  ) {
                    Swal.fire({
                      position: "top-end",
                      text: "Token expired, please re-login",
                      showConfirmButton: false,
                      timer: 1500,
                      backdrop: false,
                      width: "450px",
                    });
                    localStorage.removeItem("isVerified");
                    localStorage.removeItem("access_token");
                    localStorage.removeItem("email");
                    navigate("/signIn");
                  }
                });
            }
          }
        })
        .catch((err) => {
          const { message } = err.response.data || {};
          if (
            message &&
            (message === "Unauthorized" ||
              message === "Unauthenticated" ||
              message === "TokenExpiredError")
          ) {
            Swal.fire({
              position: "top-end",
              text: "Token expired, please re-login",
              showConfirmButton: false,
              timer: 1500,
              backdrop: false,
              width: "450px",
            });
            localStorage.removeItem("isVerified");
            localStorage.removeItem("access_token");
            localStorage.removeItem("email");
            navigate("/signIn");
          }
        });
    }
  }, [email, accessToken, pathname]);

  return null;
}
